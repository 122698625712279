import React from 'react';
import "./loading.scss";

export default function Loading(props) {
    return (
        <section className='loadingPage' id='loading' style={props.style}>
            <div className="loader">
                <div className="cube">
                    <div className="face"></div>
                    <div className="face"></div>
                    <div className="face"></div>
                    <div className="face"></div>
                    <div className="face"></div>
                    <div className="face"></div>
                </div>
            </div>

            <p>Loading...</p>
        </section>
    )
}

import React, { useEffect, useRef, useState, Suspense } from 'react';
import { useGLTF, PerspectiveCamera, Text, useProgress } from '@react-three/drei';
import { useTransform, useScroll } from 'framer-motion';
import { mainLoading } from '../../../../signlas/globle';

import './room.scss';
import { Bloom, BrightnessContrast, EffectComposer } from '@react-three/postprocessing'
import * as THREE from 'three';
import { useThree } from '@react-three/fiber';

export default function Room({ props, target }) {
  const perspectiveCameraRef = useRef();
  const { nodes, materials } = useGLTF('./models/untitled.glb');
  const { scene } = useThree();

  const { progress } = useProgress();

  useEffect(() => {
    if (progress === 100) {
      mainLoading.value = false;
    }
  }, [progress]);

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [zoom, setZoom] = useState(1);

  const [positions, setPositions] = useState({
    initial: [45, 16, -8.5],
    mid: [45, 16, -1],
    target: [-1.75, 4.75, -1],
  });

  const [rotations, setRotations] = useState({
    initial: [-Math.PI / 2, 1.309, Math.PI / 2],
    mid: [-Math.PI / 2, 1.309, Math.PI / 2],
    target: [-Math.PI / 2, 1.6, Math.PI / 2],
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(screenSize.width > 1700) {
      setPositions({
        initial: [45, 20, 0],
        mid: [40, 4.75, 0],
        target: [-1.75, 4.75, 0],
      });

      setRotations({
        initial: [-Math.PI / 2, 1.309, Math.PI / 2],
        mid: [-Math.PI / 2, 1.6, Math.PI / 2],
        target: [-Math.PI / 2, 1.6, Math.PI / 2],
      });
    }

    if (screenSize.width <= 1700) {
      setPositions({
        initial: [45, 20, 0],
        mid: [33, 20, -24],
        target: [-2.3, 4.5, 2.15],
      });

      setRotations({
        initial: [-Math.PI / 2, 1.309, Math.PI / 2],
        mid: [-2.7, 0.9, 2.8],
        target: [-2.7, 0.9, 2.8],
      });
    }

    if (screenSize.width > 1200) {
      perspectiveCameraRef.current.layers.enable(1);
      perspectiveCameraRef.current.layers.disable(2);
      perspectiveCameraRef.current.layers.disable(3);
    }

    if (screenSize.width <= 1200) {
      perspectiveCameraRef.current.layers.disable(1);
      perspectiveCameraRef.current.layers.disable(2);
      perspectiveCameraRef.current.layers.enable(3);

      setPositions({
        initial: [45, 20, 0],
        mid: [35, 30, -14.2],
        target: [-2, 4.65, 1.65],
      });

      setRotations({
        initial: [-Math.PI / 2, 1.309, Math.PI / 2],
        mid: [-2.15, 0.9, 2.25],
        target: [-2.15, 0.9, 2.25],
      });
    }

    if (screenSize.width <= 650) {
      perspectiveCameraRef.current.layers.enable(1);
      perspectiveCameraRef.current.layers.disable(3);
      perspectiveCameraRef.current.layers.enable(2);

      setPositions({
        initial: [45, 20, 0],
        mid: [34, 25, 6.8],
        target: [-2.9, 4.11, -2],
      });

      setRotations({
        initial: [-Math.PI / 2, 1.309, Math.PI / 2],
        mid: [-1.168, 1.074, 1.119],
        target: [-1.168, 1.074, 1.119],
      });
    }

    if (screenSize.width <= 900) {
      setZoom(screenSize.width * 0.001);
    } else {
      setZoom(screenSize.width <= 1700 ? 0.9 : 1);
    }
  }, [screenSize]);

  const { scrollYProgress } = useScroll({ target });

  const x = useTransform(scrollYProgress, [0, 0.15, 0.4], [positions.initial[0], positions.mid[0], positions.target[0]]);
  const y = useTransform(scrollYProgress, [0, 0.15, 0.4], [positions.initial[1], positions.mid[1], positions.target[1]]);
  const z = useTransform(scrollYProgress, [0, 0.15, 0.4], [positions.initial[2], positions.mid[2], positions.target[2]]);

  const rotX = useTransform(scrollYProgress, [0, 0.15, 0.4], [rotations.initial[0], rotations.mid[0], rotations.target[0]]);
  const rotY = useTransform(scrollYProgress, [0, 0.15, 0.4], [rotations.initial[1], rotations.mid[1], rotations.target[1]]);
  const rotZ = useTransform(scrollYProgress, [0, 0.15, 0.4], [rotations.initial[2], rotations.mid[2], rotations.target[2]]);

  useEffect(() => {
    const unsubscribeX = x.onChange((latestX) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.position.x = latestX;
      }
    });
    const unsubscribeY = y.onChange((latestY) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.position.y = latestY;
      }
    });
    const unsubscribeZ = z.onChange((latestZ) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.position.z = latestZ;
      }
    });

    const unsubscribeRotX = rotX.onChange((latestRotX) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.rotation.x = latestRotX;
      }
    });
    const unsubscribeRotY = rotY.onChange((latestRotY) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.rotation.y = latestRotY;
      }
    });
    const unsubscribeRotZ = rotZ.onChange((latestRotZ) => {
      if (perspectiveCameraRef.current) {
        perspectiveCameraRef.current.rotation.z = latestRotZ;
      }
    });

    return () => {
      unsubscribeX();
      unsubscribeY();
      unsubscribeZ();
      unsubscribeRotX();
      unsubscribeRotY();
      unsubscribeRotZ();
    };
  }, [x, y, z, rotX, rotY, rotZ]);

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (date) => {
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const targetLight = new THREE.Object3D();
  targetLight.position.set(0.2, 15.7, 0);

  scene.add(targetLight);

  return (
    <>
      <PerspectiveCamera
        ref={perspectiveCameraRef}
        makeDefault={true}
        far={1000}
        near={0.1}
        fov={26}
        zoom={zoom}
        position={positions.initial}
        rotation={rotations.initial}
      />

      <spotLight intensity={200} position={[1, 11, 0]} rotation={[-Math.PI / 2, 0, 0]} angle={2} penumbra={7} decay={2} color="#fff7d6" castShadow shadow-mapSize-width={1024} shadow-mapSize-height={1024} shadow-bias={-0.001} />
      <ambientLight intensity={1} />
      <spotLight intensity={100} angle={Math.PI / 8} penumbra={0.15} decay={2} position={[0.6, 11.5, 0]}
        rotation={[-Math.PI / 2, -0.175, 0]} // Reset rotation to default
        target={targetLight} // Set the target
      />

      <group {...props} dispose={null}>

        <group position={[3, 10, 0]} rotation={[-Math.PI / 2, 0, 0]} scale={3}>
          <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <EffectComposer
              enabled={true}
              depthBuffer={true}
            >
              <Suspense fallback={null}>
                <group position={[-96.856, 28.587, 0]} rotation={[-Math.PI / 2, 0, 0]}>
                  <mesh geometry={nodes.lamp006.geometry} material={materials.clean_retro_light_mat} position={[0, 0, -96.71]} scale={[1, 1, 3]} />
                </group>
                <mesh geometry={nodes.lamp005.geometry} material={materials.clean_bulb_light_mat} position={[-96.822, 57.654, 0.153]} rotation={[-Math.PI / 2, 0, 0]} />
                <mesh castShadow receiveShadow geometry={nodes.lamp008.geometry} material={materials.wire_filament_mat} position={[-96.822, 57.654, 0.153]} rotation={[-Math.PI / 2, 0, 0]} />
              </Suspense>
              <Bloom luminanceSmoothing={1000} />
              <BrightnessContrast
                brightness={0} // brightness. min: -1, max: 1
                contrast={0.1} // contrast: min -1, max: 1
              />
            </EffectComposer>

          </group>
        </group>

        <group position={[-4.1, -1.77, -3.2]} rotation={[0, Math.PI / 2, 0]} scale={1.5}>
          <group position={[0, 3.52, 0]} scale={0.002}>
            <group position={[0, 100, 21.916]} rotation={[-Math.PI / 2, 0, 0]} scale={100}>
              <mesh castShadow receiveShadow geometry={nodes.Corpo_Material002_0.geometry} material={materials['Material.005']} />
              <mesh castShadow receiveShadow geometry={nodes.Corpo_Material006_0.geometry} material={materials['Material.006']} />
            </group>
            {/* <mesh castShadow receiveShadow  geometry={nodes.Text_Material004_0.geometry} material={materials['Material.008']} position={[0, 100, 23.812]} scale={160} /> */}
            <Text
              position={[-5, 100, 23.812]}
              scale={20}
              fontSize={5}
              color="#005043"
              anchorX="center"
              anchorY="middle"
            >
              {formatTime(currentTime)}
            </Text>
          </group>
        </group>

        <group position={[6.81, -0.8, 7.82]} rotation={[0, Math.PI / 2, 0]} scale={0.08}>
          <mesh geometry={nodes.Material2.geometry} material={materials.Material_8} position={[97.75, 9.125, -85.725]} rotation={[0, -Math.PI / 2, 0]} scale={12.5} />
          <mesh geometry={nodes.Material2001.geometry} material={materials['0_g_g']} position={[216.625, 0, -167.625]} rotation={[0, Math.PI / 2, 0]} />
          <mesh geometry={nodes.Material2030.geometry} material={materials['0_g_g__7']} position={[118.875, 9.125, -82.5]} rotation={[0, Math.PI / 2, 0]} scale={12.5} />
          <mesh geometry={nodes.Material2033.geometry} material={materials.Color_A04_1} position={[118.375, 9.125, -82.625]} rotation={[0, Math.PI / 2, 0]} scale={12.5} />
          <mesh geometry={nodes.Material2045.geometry} material={materials.Material__7} position={[-205.672, 0, 100.623]} rotation={[0, -Math.PI / 2, 0]} />
          <mesh geometry={nodes.Material2048.geometry} material={materials.material_59} position={[-205.672, 0, 100.623]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.Material2049.geometry} material={materials['01_-_Default1']} position={[-205.672, 0, 100.623]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.Material3.geometry} material={materials['0078_DarkSlateGray']} position={[0, 0, -0.625]} rotation={[-Math.PI / 2, 0, 0]} />
          <mesh geometry={nodes.wall.geometry} material={materials.Color_B07_5} position={[97.75, 9.125, -85.725]} rotation={[0, -Math.PI / 2, 0]} scale={12.5} />
        </group>

        <group position={[-3.37, -0.02, 0]} rotation={[0, 1.571, 0]}>
          <group scale={0.2}>
            <mesh castShadow receiveShadow geometry={nodes.Cube002_Table_0.geometry} material={materials.Table} position={[-0.443, 10.455, 0.12]} rotation={[-Math.PI / 2, 0, 0]} scale={[100, 70, 170]} />
          </group>
        </group>

        <group position={[-1, 1.2, 2.45]} rotation={[Math.PI, -1.014, Math.PI]} scale={0.004}>
          <group rotation={[-1.568, 0, 0]}>
            <mesh castShadow receiveShadow geometry={nodes.Object_2.geometry} material={materials['Scene_-_Root']} />
            <mesh castShadow receiveShadow geometry={nodes.Object_3.geometry} material={materials['Scene_-_Root']} />
            <mesh castShadow receiveShadow geometry={nodes.Object_4.geometry} material={materials['Scene_-_Root']} />
            <mesh castShadow receiveShadow geometry={nodes.Object_5.geometry} material={materials['Scene_-_Root']} />
          </group>
        </group>

        <group position={[0.55, -0.71, -0.65]} scale={[1.2, 1.2, 1.1]}>
          <mesh castShadow receiveShadow geometry={nodes.Object_2001.geometry} material={materials.Big_Buttons} position={[-2.36, 3.6, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={0.4} />
          <mesh castShadow receiveShadow geometry={nodes.Object_3001.geometry} material={materials.Chassis} position={[-2.36, 3.6, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={0.4} />
          <mesh castShadow receiveShadow geometry={nodes.Object_4001.geometry} material={materials.Small_Buttons} position={[-2.36, 3.6, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]} scale={0.4} />
        </group>

        <group position={[0.03, 0, -0.35]}>
          <group position={[-2.5, 3.512, 0]} rotation={[0, 1.571, 0]} scale={[0.045, 0.045, 0.04]}>
            <mesh castShadow receiveShadow geometry={nodes.Mouse_body_low005_Mouse_0.geometry} material={materials['Mouse.001']} position={[19.901, 2.149, 0.143]} rotation={[-Math.PI / 2, 0, -1.565]} scale={100} />
            <mesh castShadow receiveShadow geometry={nodes.Mouse_bottom_flat_low005_Mouse_0.geometry} material={materials['Mouse.001']} position={[19.901, 2.149, 0.143]} rotation={[-Math.PI / 2, 0, -1.565]} scale={100} />
            <mesh castShadow receiveShadow geometry={nodes.Mouse_Click_low005_Mouse_0.geometry} material={materials['Mouse.001']} position={[19.901, 2.149, 0.143]} rotation={[-Math.PI / 2, 0, -1.565]} scale={100} />
            <mesh castShadow receiveShadow geometry={nodes.Mouse_side_low005_Mouse_0.geometry} material={materials['Mouse.001']} position={[19.901, 2.149, 0.143]} rotation={[-Math.PI / 2, 0, -1.565]} scale={100} />
            <mesh castShadow receiveShadow geometry={nodes.Mouse_wheel_low005_Mouse_0.geometry} material={materials['Mouse.001']} position={[19.863, 2.299, -3.328]} rotation={[-Math.PI / 2, 0, -1.565]} scale={100} />
          </group>
        </group>

        <group position={[0, 0, 0]}>
          <group position={[-3.85, 4.82, 0]} scale={[4, 4.2, 4.4]}>
            <mesh castShadow receiveShadow geometry={nodes.Monitor_LP_lambert1_0.geometry} material={materials.lambert1} scale={0.01} />
          </group>
        </group>

        <group layers={1} position={[1, -0.36, -0]} scale={1.1}>
          <group layers={1} position={[-2.306, 2.65, 2.542]} rotation={[-Math.PI / 2, 0, 2.214]} scale={0.8}>
            <group layers={1} rotation={[Math.PI / 2, 0, 0]}>
              <group layers={1} position={[1.13, 1.772, -1.375]} rotation={[2.88, Math.PI / 2, 0]} scale={[-0.018, -0.679, -0.853]}>
                <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_137.geometry} material={materials.Corpus} />
                <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_138.geometry} material={materials['material.002']} />
                <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_139.geometry} material={materials.material_0} />
              </group>
              <group layers={1} position={[1.137, 1.131, -0.509]} rotation={[0, 1.571, 0]} scale={[0.687, 0.018, 0.981]}>
                <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_134.geometry} material={materials.Corpus} />
                <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_135.geometry} material={materials.material_0} />
              </group>
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_4002.geometry} material={materials['.014']} position={[0.311, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_6.geometry} material={materials['.001']} position={[0.434, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_8.geometry} material={materials['.002']} position={[0.557, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_10.geometry} material={materials['.003']} position={[0.68, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_12.geometry} material={materials['.004']} position={[0.803, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_14.geometry} material={materials['.005']} position={[0.926, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_16.geometry} material={materials['.006']} position={[1.05, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_18.geometry} material={materials['.007']} position={[1.172, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_20.geometry} material={materials['.008']} position={[1.295, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_22.geometry} material={materials['.009']} position={[1.419, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_24.geometry} material={materials['.010']} position={[1.541, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_26.geometry} material={materials['.011']} position={[1.665, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_28.geometry} material={materials['.012']} position={[1.786, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_30.geometry} material={materials['.016']} position={[0.491, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_32.geometry} material={materials['.017']} position={[0.614, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_34.geometry} material={materials['.018']} position={[0.737, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_36.geometry} material={materials['.019']} position={[0.86, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_38.geometry} material={materials['.020']} position={[0.982, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_40.geometry} material={materials['.021']} position={[1.105, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_42.geometry} material={materials['.022']} position={[1.229, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_44.geometry} material={materials['.023']} position={[1.351, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_46.geometry} material={materials['.024']} position={[1.474, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_48.geometry} material={materials['.025']} position={[1.598, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_50.geometry} material={materials['.026']} position={[1.721, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_52.geometry} material={materials['.030']} position={[0.52, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_54.geometry} material={materials['.031']} position={[0.643, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_56.geometry} material={materials['.032']} position={[0.766, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_58.geometry} material={materials['.033']} position={[0.889, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_60.geometry} material={materials['.034']} position={[1.012, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_62.geometry} material={materials['.035']} position={[1.135, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_64.geometry} material={materials['.036']} position={[1.258, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_66.geometry} material={materials['.037']} position={[1.38, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_68.geometry} material={materials['.038']} position={[1.503, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_70.geometry} material={materials['.039']} position={[1.628, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_72.geometry} material={materials['.040']} position={[1.75, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_74.geometry} material={materials['.043']} position={[0.582, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_76.geometry} material={materials['.044']} position={[0.705, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_78.geometry} material={materials['.045']} position={[0.828, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_80.geometry} material={materials['.046']} position={[0.951, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_82.geometry} material={materials['.047']} position={[1.074, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_84.geometry} material={materials['.048']} position={[1.197, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_86.geometry} material={materials['.049']} position={[1.321, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_88.geometry} material={materials['.050']} position={[1.443, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_90.geometry} material={materials['.051']} position={[1.566, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_92.geometry} material={materials['.052']} position={[1.69, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_94.geometry} material={materials['.061']} position={[1.719, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_96.geometry} material={materials['.055']} position={[0.433, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_98.geometry} material={materials['Clava.064']} position={[1.962, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_100.geometry} material={materials['.057']} position={[0.681, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_102.geometry} material={materials['.058']} position={[0.833, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_104.geometry} material={materials['.059']} position={[1.449, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_106.geometry} material={materials['.060']} position={[1.595, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_108.geometry} material={materials['.056']} position={[0.561, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_110.geometry} material={materials['.063']} position={[1.84, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_112.geometry} material={materials['.054']} position={[0.31, 1.143, -0.481]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_114.geometry} material={materials['.042']} position={[0.312, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_116.geometry} material={materials['.053']} position={[1.815, 1.143, -0.601]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_118.geometry} material={materials['.041']} position={[1.873, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_120.geometry} material={materials['.029']} position={[0.313, 1.143, -0.721]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_122.geometry} material={materials['.027']} position={[1.845, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_124.geometry} material={materials['.028']} position={[1.966, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_126.geometry} material={materials['.015']} position={[0.311, 1.143, -0.84]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_128.geometry} material={materials['.013']} position={[1.911, 1.143, -0.962]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_130.geometry} material={materials['material.002']} position={[0.312, 1.143, -1.081]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_132.geometry} material={materials['.062']} position={[1.84, 1.143, -0.482]} rotation={[-Math.PI, 0, -Math.PI]} scale={[0.052, 0.005, 0.052]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_143.geometry} material={materials.material_0} position={[1.136, 0.746, 0.164]} rotation={[-Math.PI, 0, -Math.PI]} scale={[-0.142, -0.007, -0.015]} />
              <mesh castShadow receiveShadow layers={1} geometry={nodes.Object_145.geometry} material={materials.Corpus} position={[1.141, 1.148, -1.194]} rotation={[Math.PI, 0, 0]} scale={[0.769, 0.016, 0.014]} />
            </group>
          </group>
        </group>

        <group position={[-0.7, 0, 7.5]} rotation={[0, -Math.PI / 2, 0]}>
          <mesh castShadow receiveShadow geometry={nodes.Cube_0.geometry} material={materials.cover1} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_1.geometry} material={materials.paper1} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_2.geometry} material={materials['cover1.002']} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_3.geometry} material={materials['paper1.002']} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_4.geometry} material={materials.torez} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_5.geometry} material={materials['cover1.001']} />
          <mesh castShadow receiveShadow geometry={nodes.Cube_6.geometry} material={materials['paper1.001']} />
        </group>

        <group layers={2} position={[-1.67, 1.41, -0.55]} rotation={[0, 0.199, 0]} scale={[0.81, 0.6, 0.5]}>
          <group layers={2} position={[-0.02, 3.52, -0.54]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.004, 0.006, 0.006]}>
            <mesh castShadow receiveShadow layers={2} geometry={nodes.Object_2003.geometry} material={materials['Scene_-_Root.001']} position={[-417.59, -1000, 0]} rotation={[0, 0, -Math.PI / 2]} />
          </group>
        </group>

        <group layers={2} position={[-3.11, 3.652, -2]} rotation={[2.308, -1.217, 2.525]} scale={4}>
          <group layers={2} rotation={[0.197, -0.02, -0.197]} scale={0.01}>
            <mesh castShadow receiveShadow layers={2} geometry={nodes.SSwNHydlPxsUYYx.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.fmPCLkRSemlQWac.geometry} material={materials.PLMruZnLcxoCgNt} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.LQmVKBDUfvlduwU.geometry} material={materials.qzKHApwituZTUxC} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.MZgVlnVREwAXmWu_0.geometry} material={materials.TrnkWAfGduLgqLM} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.YPoKloEgOhaAHnb.geometry} material={materials.loFcbeOTkLALGur} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.ySMlAnxbqcOOfYN_0.geometry} material={materials.bliqaGpTvCuZHuk} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.DWuaFbuiStCtHXh.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.EYwxmcMJdavFtfW.geometry} material={materials.MeiGPKHwBMVqGNQ} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.ixErOejazQORIfP.geometry} material={materials.yuFXHUhPQDoVApU} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.jllDjFkFZNzYOGW.geometry} material={materials.GoEESZWjCZpqeOD} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.jxlpVheMoDZjVdT.geometry} material={materials.XioMmaZGUXvxXPb} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.KEoybOafRtqNJNh.geometry} material={materials.IfqLTOjymCZMuVu} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.nHCDQLdTvgMPJbp.geometry} material={materials.pUfGAKnCgtMMbbm} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.QUgUcmNrbmAQdPa.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.SkYYDNrQKqVDRge.geometry} material={materials.GCTfChbRIgBmWbe} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.XaStmvbQicYGoTn.geometry} material={materials.IfqLTOjymCZMuVu} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.YriphandxAMCCeO.geometry} material={materials.yuFXHUhPQDoVApU} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.ZCtyoMmbuhknTJj.geometry} material={materials.erHrUfZxAtkfXqO} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.CifRJRFlybUhpcD.geometry} material={materials.IfqLTOjymCZMuVu} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.gfCaTdiXaVSAvAF_0.geometry} material={materials.ZyKhfPnbNOCYIny} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.NXKAkZjsnbPTLxk_0.geometry} material={materials.EcHAJKlkagvhESV} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.QouRlcwrCgGeXyH_0.geometry} material={materials.hgmDQvqtKSacBdB} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.WaDbzrqgLcHkRTX.geometry} material={materials.EnwhLiFdMDwiUyz} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.FCnUJNLAiKvmJAc_0.geometry} material={materials.IfqLTOjymCZMuVu} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.jpYEgDQfzzGREjL.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.MZiAbIpINzxMuaJ_0.geometry} material={materials.TrnkWAfGduLgqLM} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.GoKkDPUheDuuSeZ.geometry} material={materials.FjQZkOEFcAMgVKb} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.LLCOsMNMwTSiaFM_0.geometry} material={materials.jUggraQHQxDJpnM} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.mCHktZAFyMAJfef.geometry} material={materials.XioMmaZGUXvxXPb} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.dhklRpTwbkGOiTk.geometry} material={materials.fexrCBexEqONlzJ} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.ffAKcDPUgFPpzDm.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.kkKyyQuSQljBsvq.geometry} material={materials.GCTfChbRIgBmWbe} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.rhWSVMrzmgmACdq.geometry} material={materials.GoEESZWjCZpqeOD} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.TpYiAaTOnMgRFmX_0.geometry} material={materials.pAavhFVnCAgSnSH} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.BILckvpwXOytcMs.geometry} material={materials.initialShadingGroup} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.SLEuRDFLKhfUOyE.geometry} material={materials.nLgTjJkjjIwxPzH} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.TvbvbWsxdamuUZs.geometry} material={materials.XvdlxyjrKESiFay} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.VglCddniAjuXwWP.geometry} material={materials.IIOPDiBWBbrUopx} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.vpUhSFYUYpnDUQE.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.XdgYNqkrKoAIJsp.geometry} material={materials.qTzpZGPXrrlpDsm} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.dsCQvEsFBIAHfbb.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.hImVmspNdsOatRs.geometry} material={materials.WSDoIreTMPBuTxz} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.OcdXOLSkbIPgJqo_0.geometry} material={materials.uApvtlLvHnjNKSG} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.SpKnavUGNMxGglY.geometry} material={materials.WeYJTXtSqeWejVb} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.YyStFrQFolEKwWF.geometry} material={materials.yuFXHUhPQDoVApU} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.jiUeIJQcPqDOUIZ.geometry} material={materials.VlnkJNnmZmryUEK} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.LcaTrKQHGWZApgK.geometry} material={materials.WSDoIreTMPBuTxz} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.rxiMoNrvziDIZct.geometry} material={materials.WeYJTXtSqeWejVb} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.quvFSzOoArSvcXY.geometry} material={materials.HTVLbrDhlLdibKi} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.lWFljklIAvFvrBM_0.geometry} material={materials.toZkefdtnUhLKyP} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.MVWilhydUNwPxpT.geometry} material={materials.fhuhwvvbmZNTsNo} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.mZCSizhtifxXBqG_0.geometry} material={materials.myxBndjHzHABgaf} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.pkonsGObqbDcbjn_0.geometry} material={materials.qTzpZGPXrrlpDsm} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.tYhdRFkjheTEnwh.geometry} material={materials.euLqrXMAOeSzxXG} />
            <mesh castShadow receiveShadow layers={2} geometry={nodes.WUgXyTEbZmkapMh_0.geometry} material={materials.nfKhILGSzwibuSI} />
          </group>
        </group>

        <group layers={3} position={[4.43, -2, -0.8]} rotation={[0, 0.395, 0]} scale={[1.42, 0.92, 1.2]}>
          <group layers={3} position={[-3.286, 6.003, -2.454]} rotation={[-Math.PI / 2, 0, 0]} scale={[0.004, 0.006, 0.006]}>
            <mesh castShadow receiveShadow layers={3} geometry={nodes.Object_2004.geometry} material={materials['Scene_-_Root.002']} position={[-417.59, -1000, 0]} rotation={[0, 0, -Math.PI / 2]} />
          </group>
        </group>

        <group layers={3} position={[-2.39, 3.66, 3]} rotation={[0.012, 0.398, 0.525]} scale={0.25}>
          <group layers={3} rotation={[-Math.PI / 2, 0, 0]}>
            <group layers={3} rotation={[Math.PI / 2, 0, 0]}>
              <group layers={3} position={[1, 2.427, -4.555]} rotation={[0, 1.571, 0]} scale={[1.002, 2.583, 2.146]}>
                <mesh castShadow receiveShadow layers={3} geometry={nodes.Object_4003.geometry} material={materials.Material} />
                <mesh castShadow receiveShadow layers={3} geometry={nodes.Object_5003.geometry} material={materials['Material.015']} />
                <mesh castShadow receiveShadow layers={3} geometry={nodes.Object_6002.geometry} material={materials['Material.016']} />
              </group>
            </group>
          </group>
        </group>

        <mesh castShadow receiveShadow geometry={nodes.Cube002.geometry} material={materials['Material.007']} position={[0.07, -0.18, -0.36]} rotation={[Math.PI / 2, 0, Math.PI / 2]} scale={[-1.635, -1, -0.04]} />
        <mesh castShadow receiveShadow geometry={nodes.pPlane1_lambert2_0.geometry} material={materials.lambert2} position={[0.09, 0.03, -0.38]} scale={[0.34, 1, 0.56]} />
        <mesh geometry={nodes.glow.geometry} material={materials['Material.003']} position={[0.1, 11.65, 0]} scale={0.2} />
        <mesh geometry={nodes.monitorScreen.geometry} material={materials['Material.002']} position={[-3.765, 4.85, 0]} rotation={[0, 0, -Math.PI / 2]} scale={[0.52, 1, 1]} />
      </group>
    </>
  );
}

useGLTF.preload('./models/scene.gltf');

import React from 'react';
import BearCarousel, { BearSlideCard } from 'bear-react-carousel';

import "./project.scss";
import * as features from '../../../';
import { projects } from '../../../../services';

export default function Project({ id }) {
    const project = projects.find(project => project.id === parseInt(id))

    const bearSlideItemData = project.imgs.map(img => {
        return <BearSlideCard key={img}>
            <div
                className='projectImg'
                style={{
                    background: `url(${img}) no-repeat center center/cover`,
                    width: "100%"
                }}
            />
        </BearSlideCard>
    });

    return (
        <div className='projectPage'>
            <div className='projectContainer'>
                <div className='imgsContainer'>
                    <div className="overLayLeft" />
                    <div className="overLayRight" />
                    <BearCarousel
                        data={bearSlideItemData}
                        slidesPerView={1}
                        isEnableNavButton
                        isCenteredSlides
                        isEnableLoop
                        isLazy
                        isEnableAutoPlay
                        autoPlayTime={3000}
                        moveTime={1000}
                        movePercentage={0.1}
                    />
                </div>

                <div className='projectInfo'>
                    <div>
                        <h2>{project.name} {project.link && <a href={project.link} target="_blank" rel="noreferrer">Click to view the project</a>}</h2>
                        <span className="lang" >{project.lang}</span>
                        {project.status && <span className='status'> <span style={{ color: "white" }}> — </span> {project.status}</span>}
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: project.description }} />
                </div>
            </div>
        </div>
    )
}

import React, { useEffect, useRef, useState } from 'react';
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";

import './projects.scss';
import * as features from '../../features';
import { projects } from '../../services';
import { projectsLoading } from '../../signlas/globle';
import { Variant } from '../../utils';

export default function Projects() {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const dialogRef = useRef(null);

  const numProjects = projects.length * 250;

  useEffect(() => {
    projectsLoading.value = false;
  }, []);

  const openDialog = (id) => {
    setIsOpen(true);
    setId(id);
    dialogRef.current.showModal();
  };

  const closeDialog = () => {
    setIsOpen(false);
    document.documentElement.style.overflowY = 'visible';
    dialogRef.current.close();
  };

  return (
    <section className='projectsPage' id='Project'>
      <div className='allContent'>
        <div className='heading'>
          <h2>Highlighted Projects</h2>
          <p>Here are some of the projects I have worked on.</p>
        </div>

        <div className='projects'>
          {projects && projects.slice().reverse().map((item, index) => (
            <motion.div
              key={index}
              className='projectBox'
              style={{
                rotateZ: `${item.rotate}deg`
              }}
              onClick={() => {
                document.documentElement.style.overflowY = 'hidden';
                openDialog(item.id);
              }}
              initial="offscreen" 
              whileInView="onscreen" 
              viewport={{ once: false }} 
              variants={Variant(item.id % 2 === 0 ? 'fadeRight' : 'fadeLeft', 'tween', 0.5, 0.1)}
            >
              <div className='projectCon'>
                <div className='proj-img' style={{ backgroundImage: `url(${item.img})` }}>
                  <div className='overlay' />
                  <div className='projectInfo'>
                    <span className='pro-name'>{item.name}</span>
                    <span className='pro-lang'>{item.lang}</span>
                    {item.status && <span className='pro-status'>{item.status}</span>}
                  </div>
                  <p className='view'>Click for more information →</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <dialog ref={dialogRef}>
          <div className='header'>
            <button className='closeBtn' onClick={closeDialog}><IoClose /></button>
          </div>
          {id &&
            <features.Project id={id} />
          }
        </dialog>
      </div>
    </section>
  )
}

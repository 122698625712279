import React, { useRef, useEffect, useState } from 'react';
import { motion, useScroll, useTransform, useMotionValueEvent } from "framer-motion";

import './main.scss';
import * as page from '../';
import * as features from '../../features';

import { Canvas } from '@react-three/fiber';
import { aboutLoading, footerLoading, mainLoading, projectsLoading } from '../../signlas/globle';
import { useSignalEffect } from '@preact/signals-react';

export default function Main(props, { defualt }) {
  const target = useRef(null);
  const about = useRef(null);
  const [isCentered, setisCentered] = useState(false);
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const { scrollYProgress } = useScroll({
    target,
    offset: ["start start", "end start"]
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (screenSize.width <= 1700) {
      setisCentered(true);
    } else {
      setisCentered(false);
    }
  }, [screenSize.width]);

  const opacity = useTransform(scrollYProgress, [0.399, 0.4], ["1", "0"]);
  const zIndex = useTransform(scrollYProgress, [0.399, 0.4], ["", "-2"]);
  const displayRoom = useTransform(scrollYProgress, [0.4, 0.41], ["block", "none"]);
  const visibilityRoom = useTransform(scrollYProgress, [0.4, 0.41], ["visible", "hidden"]);

  const opacityWrapper = useTransform(scrollYProgress, [0, 0.15], ["1", "0"]);
  const wrapperX = useTransform(scrollYProgress, [0, 0.3], ["0", "200%"]);

  const opacityImg = useTransform(scrollYProgress, [0.40, 0.43], ["0", "1"]);

  const fullText = 'Welcome to my portfolio! Take a look around and discover my work.';
  const textRef = useRef(null);
  const cursorRef = useRef(null);
  const speed = 100;
  const iRef = useRef(0);

  useSignalEffect(() => {
    const typeWriter = () => {
      if (mainLoading.value === false && aboutLoading.value === false && projectsLoading.value === false && footerLoading.value === false) {
        if (iRef.current < fullText.length) {
          textRef.current.textContent += fullText.charAt(iRef.current);
          iRef.current++;
          setTimeout(typeWriter, speed);
        } else {
          setTimeout(() => {
            if (cursorRef.current) {
              cursorRef.current.style.visibility = 'hidden';
            }
          }, 3000);
        }
      }
    };
    typeWriter();
  }, [mainLoading, aboutLoading, projectsLoading, footerLoading]);

  const [hookedYPostion, setHookedYPosition] = React.useState(0);
  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    setHookedYPosition(latest);
  })

  return (
    <section className='mainPage' ref={target}
    >
      <motion.div className='wrapper' style={{ opacity: opacityWrapper }}>
        <motion.div
          className='wrapperText'
        >
          <h1>Hey, I’m
            <span>{"<Bagher />"}</span>
          </h1>
          <div>
            <p className='headingText' ref={textRef} />
            <div
              className='cursor'
              ref={cursorRef}
            />
          </div>
        </motion.div>
      </motion.div>

      <motion.div
        className='room'
        style={{
          opacity,
          zIndex,
          // display: displayRoom,
          visibility: visibilityRoom,
        }}
      >
        <Canvas shadows>
          <features.Room target={target} />
        </Canvas>
      </motion.div>

      <motion.div
        className='aboutContainer'
        name="about"
        id='about'
        ref={about}
        style={{
          opacity: opacityImg,
        }}
      >
        <page.About />
      </motion.div>
    </section>
  )
}
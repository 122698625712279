import React, { useEffect, useState } from 'react';
import { IoLocationSharp } from "react-icons/io5";
import { IoMdMail, IoLogoGithub, IoLogoLinkedin } from "react-icons/io";
import { motion } from "framer-motion";

import './footer.scss';
import { footerLoading } from '../../../../signlas/globle';
import { Variant } from '../../../../utils';

export default function Footer() {
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        footerLoading.value = false;
    }, []);

    return (
        <footer className='contact' id='Contact'>
            <div className='social-contact'>
                <ul className='contact-list'>
                    <motion.li 
                        initial="offscreen" 
                        whileInView="onscreen" 
                        viewport={{ once: true }} 
                        variants={Variant('fadeDown', 'tween', 0.5, 0.1)}
                    >
                        <a href="https://maps.app.goo.gl/9mcAszonrJjJwAjs7">
                            <IoLocationSharp className="icon" />
                            <span className="">Stockholm</span>
                        </a>
                    </motion.li>
                    <motion.li
                        initial="offscreen" 
                        whileInView="onscreen" 
                        viewport={{ once: true }} 
                        variants={Variant('fadeDown', 'tween', 0.5, 0.2)}
                    >
                        <a href="mailto:Bagherhosseini03@gmail.com" title="Send me an email">
                            <IoMdMail className="icon" />
                            <span>Email me</span>
                        </a>
                    </motion.li>
                    <motion.li
                        initial="offscreen" 
                        whileInView="onscreen" 
                        viewport={{ once: true }} 
                        variants={Variant('fadeDown', 'tween', 0.5, 0.3)}
                    >
                        <a href="https://github.com/bagherhosseini">
                            <IoLogoGithub className="icon" />
                            <span>Github</span>
                        </a>
                    </motion.li>
                    <motion.li
                        initial="offscreen" 
                        whileInView="onscreen" 
                        viewport={{ once: true }} 
                        variants={Variant('fadeDown', 'tween', 0.5, 0.4)}
                    >
                        <a href="https://www.linkedin.com/in/bagherhosseini/">
                            <IoLogoLinkedin className="icon" />
                            <span>Linkedin</span>
                        </a>
                    </motion.li>
                </ul>
            </div>

            <p className='Copyright'>Copyright <span>&copy;</span> {currentYear} Bagher Hosseini</p>
        </footer>
    )
}
import * as assetsImg from '../assets/images';

// rotate between -2 to 2
const projects = [
    // {
    //     id: 1,
    //     name: 'Check out my Github for more projects',
    //     lang: '',
    //     img: assetsImg.Github,
    //     imgs: [
    //         assetsImg.Falakdeh,
    //         assetsImg.Falakdeh1,
    //     ],
    //     link: "https://github.com/bagherhosseini",
    // },
    {
        id: 2,
        name: 'Twitter',
        rotate: 1.86,
        description: `
            This project is a Twitter clone, 
            developed using modern web technologies to ensure a seamless user experience across all devices. 
            I built the website from scratch, focusing on creating a responsive and intuitive interface. 
            This project provided me with valuable insights into the principles of responsive design and the techniques needed to implement it effectively. 
            Additionally, I gained experience in translating pre-existing designs into functional, efficient code.
        `,
        lang: 'Html & SCSS',
        img: assetsImg.Twitter,
        imgs: [
            assetsImg.Twitter1,
            assetsImg.Twitter2,
            assetsImg.Twitter3,
            assetsImg.Twitter4,
        ],
        link: "https://twitter-b.netlify.app/",
        githubLink: "https://github.com/bagherhosseini/twitter"
    },
    {
        id: 3,
        name: 'Weather',
        rotate: -0.095,
        description: `
            This project is a Weather app, developed using React and SCSS. 
             I created this website to explore how different APIs work and to enhance my skills in working with them.
             I designed and implemented the user interface from scratch. 
             Through this project, I gained valuable insights into handling asynchronous data fetching, 
             managing state in a React application, and using SCSS for modular and maintainable styling.
             Additionally, I worked extensively with APIs to fetch real-time weather data and present it through a user-friendly interface.
        `,
        lang: 'React & SCSS',
        img: assetsImg.Weather,
        imgs: [
            assetsImg.Weather1,
            assetsImg.Weather2,
        ],
        link: "https://weather-b.netlify.app/",
        githubLink: "https://github.com/bagherhosseini/weather"
    },
    {
        id: 4,
        name: '3D Room',
        rotate: -0.61,
        description: `
            This project is a 3D room, developed using React and SCSS. It is one of the projects I am most proud of.
             It posed a unique challenge as it was built without relying on any 3D frameworks or libraries, 
             using only CSS for all styling. 
             This approach required meticulous attention to detail and creative problem-solving to achieve the desired 3D effect.
            <br />
            <br />
            Despite the time-consuming nature of the project, 
             the effort invested proved invaluable in expanding my skills in CSS and understanding its capabilities in creating 3D objects and environments. 
             Creating each element of the room, 
             from walls and furniture to the computer, 
             provided a deep dive into CSS transformations.
            <br />
            <br />
            This project not only enhanced my proficiency in frontend development with React but also deepened my appreciation for the versatility of CSS in creating immersive visual experiences. 
            Overall, it was a rewarding endeavor that enriched my knowledge and skill set in web development.
        `,
        lang: 'React & SCSS',
        img: assetsImg.Room,
        imgs: [
            assetsImg.Room,
        ],
        link: "https://3d-room-1.netlify.app/",
        githubLink: "https://github.com/bagherhosseini/3d-room"
    },
    {
        id: 5,
        name: 'Admin Dashboard',
        rotate: 1.2,
        description: `
            This project is an Admin Dashboard created with Next.js, a project of which I'm particularly proud.
             As the tech lead of this group project, 
             I guided our team in developing a comprehensive tool for ecommerce owners to manage orders and products. 
             The dashboard enables ecommerce owners to view, add, edit, and remove products, as well as access detailed product information and orders.
            <br />
            <br />
             Working with Next.js on this project was enlightening. 
             I delved deeply into styled components and their integration within Next.js, 
             gaining practical skills that enhanced the dashboard's aesthetic appeal and usability. 
             Managing state in a complex application taught me valuable lessons in organizing and optimizing code for scalability.
            <br />
            <br />
             Moreover, this project provided valuable insights into the complexities of ecommerce development. 
             Understanding how to structure and implement features that cater specifically to ecommerce needs was a significant learning curve.
             Additionally, ensuring that orders were Stripe verified emphasized the importance of secure payment processing in ecommerce platforms.
             Overall, building this Admin Dashboard strengthened my expertise in frontend development with Next.js while expanding my knowledge of ecommerce application architecture.
        `,
        lang: 'Next.js',
        img: assetsImg.AdminDashboard1,
        imgs: [
            assetsImg.AdminDashboard1,
            assetsImg.AdminDashboard2,
            assetsImg.AdminDashboard3,
            assetsImg.AdminDashboard4,
            assetsImg.AdminDashboard5,
            assetsImg.AdminDashboard6,
            assetsImg.AdminDashboard7,
            assetsImg.AdminDashboard,
        ],
        githubLink: "https://github.com/bagherhosseini/admin-dashboard"
    },
    {
        id: 6,
        name: 'E-Commerce',
        rotate: 1.99,
        description: `
            This project is an E-Commerce website developed using Next.js. 
             Serving as the tech lead in this group project, 
             I guided our team through the development of a comprehensive ecommerce for managing orders and products. 
             The website allows users to browse products, add items to their cart, and complete purchases. 
             Upon purchase, products are seamlessly integrated into the admin dashboard's order management system.
            <br />
            <br />
            Building this website posed significant challenges, 
             requiring a deep understanding of ecommerce platforms and user experience design principles. 
             I utilized styled components to craft a seamless and intuitive user interface. 
             This project is closely connected to my admin dashboard project, 
             geting data and product information sourced directly from there.
            <br />
            <br />
            Developing this project provided valuable insights into complex state management and secure payment processing within ecommerce applications. 
            It reinforced my skills in frontend development with Next.js while expanding my knowledge of backend integration and ecommerce functionalities.
        `,
        lang: 'Next.js',
        img: assetsImg.ECommerce,
        imgs: [
            assetsImg.ECommerce,
            assetsImg.ECommerce1,
            assetsImg.ECommerce2,
            assetsImg.ECommerce3,
        ],
        link: "https://e-commerce-two-opal.vercel.app/",
        githubLink: "https://github.com/bagherhosseini/e-commerce"
    },
    {
        id: 7,
        name: 'Chat',
        rotate: -1.62,
        description: `
            This project is a chat application built using React and NestJs, 
             presenting a unique challenge due to its requirement for real-time communication between users. 
             Developing this chat application involved a deep exploration of WebSockets and their implementation, 
             ensuring seamless and instantaneous message exchange.
            <br />
            <br />
            Furthermore, the project deepened my understanding of WebSockets and their pivotal role in creating responsive and interactive web applications. 
              Integrating voice and video call features added another layer of complexity, 
              requiring meticulous attention to backend development.
            <br />
            <br />
            Despite the significant time and effort invested, 
             the project proved immensely rewarding. 
             It expanded my knowledge and skills in frontend and backend development, 
             particularly enhancing my proficiency in backend systems within NestJs. 
             Overall, this experience was a valuable learning journey, 
             underscoring the critical aspects of real-time communication and robust backend architecture in modern web applications.
        `,
        lang: 'React & Nest Js',
        img: assetsImg.Chat,
        imgs: [
            assetsImg.Chat,
            assetsImg.Chat1,
            assetsImg.Chat2,
            assetsImg.Chat3,
            assetsImg.Chat4,
        ],
        githubLink: "https://github.com/bagherhosseini/G-post-nest"
    },
    {
        id: 8,
        name: 'Hami',
        rotate: 0.37,
        description: `
            This project is for a client and involves developing a website where creators can share their content with others. 
             Users must pay a fee set by the creators to access this content. 
             The project is built using React and NestJs and is the largest project I've ever worked on. 
             It has a lot of features and I am actively involved in both the frontend and backend development. 
             As the main developer and project lead, 
             I am responsible for overseeing the entire development process and ensuring the successful implementation of all functionalities.
            <br />
            <br />
            My role involved translating the design into code and collaborating closely with the designer to provide feedback. 
             I also contributed to the backend, 
             working with other developers to implement a secure payment system.
            <br />
            <br />
            The project is still ongoing and has presented several challenges. 
             Planning the project and leading the development team was one significant aspect. 
             Technical challenges included state management and writing scalable code. 
             Despite these hurdles, 
             I am pleased with the solutions we’ve implemented and look forward to seeing the final product come to life.
        `,
        lang: 'React & Nest Js',
        status: "In progress",
        img: assetsImg.Hami,
        imgs: [
            assetsImg.Hami,
            assetsImg.Hami1,
            assetsImg.Hami2,
            assetsImg.Hami3,
            assetsImg.Hami4,
            assetsImg.Hami5,
            assetsImg.Hami6,
            assetsImg.Hami7,
            assetsImg.Hami8,
            assetsImg.Hami9,
            assetsImg.Hami10,
            assetsImg.Hami11,
        ],
        githubLink: "https://github.com/bagherhosseini/Hami"
    },
    {
        id: 9,
        name: 'Falakdeh',
        rotate: -1.38,
        description: `
            This project involves creating a residential website for a client, utilizing React.
             My responsibilities for this part of the project included designing the site and implementing the design into code.
             The website is integrated with an admin dashboard, 
             which serves to manage information and other functionalities. 
             As the development progresses, 
             additional features and sections will be incorporated to enhance functionality and user engagement.
             This ongoing project aims to deliver a final product that exceeds the client's expectations and provides an exceptional online experience for residents and visitors alike.
        `,
        lang: 'React',
        status: "In progress",
        img: assetsImg.Falakdeh,
        imgs: [
            assetsImg.Falakdeh,
            assetsImg.Falakdeh1,
        ],
        githubLink: "https://github.com/EhsanFox/falakdehClient"
    },
    {
        id: 10,
        name: 'Falakdeh admin dashboard',
        rotate: 0.81,
        description: `
            This project involves creating an admin dashboard for a client's residential website, utilizing React and NestJs
              My responsibilities include using styled components to develop a fully functional admin dashboard, 
              allowing the admin to manage various parts of the residential website. Additionally, 
              I am responsible for assisting with the server-side development, which includes handling authentication, reservations, and other functionalities. 
              As the development progresses, I will continue to ensure the dashboard meets all client requirements and provides a seamless user experience.
        `,
        lang: 'React & Nest Js',
        status: "In progress",
        img: assetsImg.FalakdehAdminDashboard,
        imgs: [
            assetsImg.FalakdehAdminDashboard,
        ],
        githubLink: "https://github.com/EhsanFox/falakdehAdmin"
    },
    {
        id: 11,
        name: 'BlankTech',
        rotate: -0.2,
        description: `
            This project involves developing a Content Management System (CMS) in collaboration with a friend. 
             Our goal is to create a CMS that is efficient, user-friendly, and robust. 
             My responsibilities include designing a seamless, secure, clean, well structured and scalable frontend solution, 
             as well as assisting with backend development and other project tasks.
            <br />
            <br />
            This project offers a valuable opportunity to deepen our understanding of CMS architecture and development. 
             Although it is in the early stages, 
             we are actively working on it and are excited about the potential to learn and grow through this experience.
        `,
        lang: 'React & Nest Js & CMS',
        status: "In progress",
        img: assetsImg.BlankTech,
        imgs: [
            assetsImg.BlankTech,
        ],
        githubLink: "https://github.com/BlankTech-dev"
    },
    {
        id: 12,
        name: 'NovaSpark',
        rotate: -1.4,
        description: `
            This project is an e-commerce platform for sustainable drinkware, built using React and Zustand for state management. 
            Developing this platform required creating a seamless user experience and efficient state updates, 
            ensuring a smooth shopping process.
            <br />
            <br />
            The project deepened my understanding of React's component-based architecture and Zustand's simplicity in state management. 
            Implementing features like a shopping cart added complexity, 
            enhancing my skills in frontend development.
        `,
        lang: 'React',
        img: assetsImg.NovaSpark1,
        imgs: [
            assetsImg.NovaSpark1,
            assetsImg.NovaSpark2,
            assetsImg.NovaSpark3,
            assetsImg.NovaSpark4,
            assetsImg.NovaSpark5,
            assetsImg.NovaSpark6,
            assetsImg.NovaSpark7,
        ],
        link: "https://novaspark.bagher.dev",
        githubLink: "https://github.com/bagherhosseini/novaspark"
    },
    {
        id: 13,
        name: 'Innerweb',
        rotate: 1.3,
        description: `
            This project is a portfolio website for a creative agency, designed to highlight their expertise in branding, development,
             and design. Built with Webflow, the site incorporates modern 3D visuals, bold typography,
             and interactive elements to create an engaging user experience.
            <br />
            <br />
            Developing this project enhanced my understanding of crafting visually compelling interfaces and optimizing responsiveness.
             Implementing features like dynamic project showcases and testimonial sections deepened my skills in creating interactive, 
             user-centric designs.
        `,
        lang: 'Webflow',
        img: assetsImg.InnerWeb,
        imgs: [
            assetsImg.InnerWeb,
            assetsImg.InnerWeb2,
            assetsImg.InnerWeb3,
            assetsImg.InnerWeb4,
            assetsImg.InnerWeb5,
        ],
        link: "https://innerweb.webflow.io",
    },
];

export default projects;
